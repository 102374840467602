import React, { useState, useRef, useEffect } from "react";
import { TextField, Box, Container, CircularProgress } from "@mui/material";
import Swal from "sweetalert2";
import DiscountImage from "../assets/images/DiscountImage.jpg";

const DemoPopup = ({ onClose }) => {
  const [formData, setFormData] = useState({
    email: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const sectionRef = useRef(null);

  const handleScroll = () => {
    if (sectionRef.current) {
      const { top } = sectionRef.current.getBoundingClientRect();
      const isInView = top < window.innerHeight * 0.5 && top >= 0;
      setIsScrolled(isInView);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
  };

  const scheduleDemo = async (event) => {
    event.preventDefault();
    const { email } = formData;
    if (!validateEmail(email)) {
      Swal.fire("Error!", "Email is invalid!", "error");
      return;
    }

    setIsSubmitting(true);

    try {
      const response = await fetch(
        "https://prodapi2.legittai.com/api/notification/sendMail",
        {
          method: "POST",
          body: JSON.stringify({
            email: formData.email,
            senderEmail:
              "gaurav.verma@legittai.com, harshdeep.rapal@legittai.com, dhruv.ahuja@legittai.com",
          }),
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
          },
        }
      );

      if (response.ok) {
        Swal.fire(
          "Success!",
          "Your request has been submitted successfully. We will get back to you soon!",
          "success"
        ).then(() => {
          onClose(); // Close DemoPopup when Swal is closed
        });

        setFormData({
          email: "",
        });
      } else {
        Swal.fire(
          "Error!",
          "Something went wrong while sending email. Try again or email at info@legittai.com!",
          "error"
        ).then(() => {
          onClose(); // Close DemoPopup when Swal is closed
        });
      }
    } catch (err) {
      console.error("Error:", err);
      Swal.fire(
        "Error!",
        "Something went wrong while sending email. Try again or email at info@legittai.com!",
        "error"
      ).then(() => {
        onClose(); // Close DemoPopup when Swal is closed
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <div className="bg-[#fff] max-w-[800px] w-full overflow-hidden rounded-lg shadow-2xl md:grid md:grid-cols-3 relative">
        <button
          onClick={onClose}
          className="absolute top-2 right-2 bg-gray-200 rounded-full p-1"
        >
          <svg
            className="h-6 w-6 text-gray-600"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
        <img
          alt=""
          src={DiscountImage}
          className="h-32 w-full object-cover md:h-full"
        />
        <div className="p-4 text-center sm:p-6 md:col-span-2 lg:p-8">
          <div className="mx-auto max-w-7xl pt-[30px]">
            <div className="mx-auto grid max-w-3xl grid-cols-1 gap-x-8 gap-y-4 lg:max-w-none">
              <div className="w-full">
                <div className="font-[800] text-center text-[40px] rounded-lg">
                  Talk to our experts
                </div>

                <p className="text-[15px] text-center mt-1">
                  Schedule a call to discuss your requirements and how Legitt AI
                  can solve them for you.
                </p>
              </div>

              <div className="flex flex-col justify-center gap-2 items-center">
                {/* <TextField
                  required
                  fullWidth
                  className="demo-form-input-fields demo-popup-when-leave-input-field demo-popup-input-field"
                  id="email"
                  label="Email Address"
                  variant="outlined"
                  value={formData.email}
                  onChange={handleChange}
                /> */}

                <a
                  href="https://savvycal.com/harshdeep/1381cd4b"
                  rel="nofollow"
                  data-savvycal-embed
                  className="common-button hidden lg:flex justify-between items-center w-full lg:flex lg:w-auto lg:order-1"
                  target="_blank"
                >
                  Schedule Demo with Expert
                </a>

                {/* <button
                  className="common-button w-[160px] flex items-center justify-center"
                  type="submit"
                  onClick={scheduleDemo}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    "Get A Demo"
                  )}
                </button> */}
              </div>
              <div className="flex flex-col justify-center items-center mt-[0px]">
                <div className="mt-2 text-[0.75rem] text-center text-black">
                  By scheduling demo, you accept our{" "}
                  <a
                    className="text-[#06526D]"
                    style={{ textDecoration: "underline" }}
                    href="/privacy-policy"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    privacy policy
                  </a>{" "}
                  &{" "}
                  <a
                    className="text-[#06526D]"
                    style={{ textDecoration: "underline" }}
                    href="/terms-and-conditions"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    terms and conditions
                  </a>
                  .
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DemoPopup;
