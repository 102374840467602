import "./App.css";
import { SnackbarProvider } from "notistack";
import Zoom from "./Components/SnackbarEffects/Zoom";
import MyRoutes from "./Routes";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import SavvyCal from "./Components/Savycal";

function App() {
  return (
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      autoHideDuration={3000}
      TransitionComponent={Zoom}
    >
      <div className="App">
        <Router basename="electronic-signature">
          <Routes>
            <Route path="*" element={<MyRoutes />} />
          </Routes>
        </Router>
        <SavvyCal />
      </div>
    </SnackbarProvider>
  );
}

export default App;
