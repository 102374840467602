import React, { useState, useEffect } from "react";
import { Dialog, DialogContent } from "@mui/material";
import DemoPopup from "./DemoPopup";

const EmailCollectionPopup = ({ isOpen, onClose, onSubmitEmail }) => {
  const [email, setEmail] = useState("");
  const [demoPopupClosed, setDemoPopupClosed] = useState(false);

  useEffect(() => {
    const hasClosed = localStorage.getItem("emailPopupClosed");
    if (hasClosed === "true") {
      setDemoPopupClosed(true);
    }

    const handleBeforeUnload = () => {
      localStorage.removeItem("emailPopupClosed");
    };
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const handleClose = () => {
    localStorage.setItem("emailPopupClosed", "true");
    setDemoPopupClosed(true);
    onClose();
  };

  const handleSubmitEmail = (email) => {
    if (email) {
      onSubmitEmail(email);
      setEmail("");
      handleClose();
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        style: {
          width: "100%",
          backgroundColor: "transparent",
          boxShadow: "none",
          overflowY: "hidden",
          maxWidth: "800px",
          overflowX: "hidden",
          borderRadius: "8px",
        },
      }}
    >
      <DialogContent>
        <DemoPopup onClose={handleClose} />
      </DialogContent>
    </Dialog>
  );
};

export default EmailCollectionPopup;
