
import { useEffect } from "react";

function SavvyCal() {

  useEffect(() => {
    const script1 = document.createElement("script");
    script1.innerHTML = `window.SavvyCal=window.SavvyCal||function(){(SavvyCal.q=SavvyCal.q||[]).push(arguments)};`;

    const script2 = document.createElement("script");
    script2.src = "https://embed.savvycal.com/v1/embed.js";
    script2.async = true;

    const script3 = document.createElement("script");
    script3.innerHTML = `SavvyCal('init');`;

    document.head.appendChild(script1);
    document.head.appendChild(script2);
    document.head.appendChild(script3);

    return () => {
      document.head.removeChild(script1);
      document.head.removeChild(script2);
      document.head.removeChild(script3);
      window.removeEventListener("savvycal:schedule", () => {});
    };
  }, []);

  return (
    <div></div>
  );
}

export default SavvyCal;
